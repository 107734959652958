// these variables are set in the ajax_include.inc.php file. The XhrService won't work without them.
declare const siteCode: string
declare const languageCode: string

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'TRACE'

/**
 * static class for optimized ajax calls
 */
export class XhrService {
    static defaultUrl = '/module/dcshop/GeneralAjaxData.php'

    /**
     * Creats an XHR (ajax) call to the generalAjaxData.php file.
     * @param fn function to call in the generalAjaxData.php. Make sure it is in the allowed functions array (l 35)
     * @param body whatever payload you want to send
     * @param method usually post
     * @param responseType can be any of the following: "" (default) | "arraybuffer" | "blob" | "document" | "json" | "text"
     *
     * If used in Typescript the return type can be specified as T;
     * e.g.
     * XhrService.generalAjaxCall<number>('connectionTest', {}).then(res => {
     *     if(res === 100) {
     *         ToastService.showToast('success', 'GeneralAjaxData works successfully')
     *     }
     * })
     * res will then be treated as a number.
     */
    static async generalAjaxCall<T>(fn: string, body: Object | FormData, method: HTTPMethod = 'POST', responseType?: XMLHttpRequestResponseType): Promise<T> {
        return this.sendRequest<T>(method, `${this.defaultUrl}?function=${fn}&site=${siteCode}&language=${languageCode}`, body, responseType)
    }

    /**
     * Creates a custom ajax call to any resource you like
     * @param method HTTP Method to use
     * @param url The url that sould be called
     * @param body The payload you want to send
     * @param responseType can be any of the following: "" (default) | "arraybuffer" | "blob" | "document" | "json" | "text"
     */
    static async sendRequest<T>(method: HTTPMethod, url: string, body?: Object | FormData, responseType?: XMLHttpRequestResponseType): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            if (responseType) {
                xhr.responseType = responseType
            }
            let data: FormData | string
            if(body instanceof FormData) {
                data = body
                method = 'POST'
                xhr.open(method, url)
            } else if (typeof body === 'object' && Object.keys(body).length > 0) {
                xhr.open(method, url)
                xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
                data = JSON.stringify(body)
            } else {
                xhr.open(method, url)
            }

            xhr.onload = () => {
                if (xhr.status !== 200) {
                    // analyze HTTP status of the response
                    reject(`Error ${xhr.status}: ${xhr.statusText}`) // e.g. 404: Not Found
                } else {
                    // show the result
                    if (xhr.getResponseHeader('content-type') === 'application/json') {
                        resolve(JSON.parse(xhr.response))
                    } else {
                        resolve(xhr.response)
                    }
                }
            }
            xhr.send(data)
        })
    }
}
