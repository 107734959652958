export const initScrolltopButton = (scrolltop, showButtonAt) => {
    const checkScrollPosition = () => {
        if (window.scrollY > showButtonAt) {
            scrolltop.classList.add('sticky');
            const scrolltopButtonBottom = scrolltop.offsetTop + scrolltop.offsetHeight;
            scrolltop.style.bottom = '';
        } else {
            scrolltop.classList.remove('sticky');
        }
    };

    window.addEventListener('scroll', () => {
        checkScrollPosition();
    });

    scrolltop.addEventListener('click', () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    });

    checkScrollPosition();
};