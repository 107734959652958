import {prevAll, nextAll} from "./utilities/functions";

function selectPrevAndNextStars(star) {
    let prevAllStars = prevAll(star);
    let nextAllStars = nextAll(star);

    for (let i of prevAllStars) {
        i.classList.add("rating__star--active");
    }

    for (let i of nextAllStars) {
        i.classList.remove("rating__star--active");
    }
}

function selectActiveStars(stars, value = 0) {
    let activeStar = false;
    for (let star of stars) {
        if (parseInt(star.dataset.value) === parseInt(value)) {
            activeStar = star;
            star.classList.add("rating__star--active");
        }
    }
    selectPrevAndNextStars(activeStar);
}

export function initRatingStars(wrapper = document) {
    for (let starsWrapper of wrapper.querySelectorAll(".js-setRatingGroup")) {
        let input = starsWrapper.querySelector('input');
        let activeValue = 0;
        let clickable = false;

        if (starsWrapper.classList.contains("clickable")) {
            clickable = true;
        }

        if (input) {
            let stars = starsWrapper.querySelectorAll('.js-setRating');
            activeValue = input.value;

            if (stars) {
                selectActiveStars(stars, activeValue);
                if (!clickable) {
                    for (let star of stars) {
                        star.addEventListener("click", function () {
                            star.classList.add("rating__star--active");
                            selectPrevAndNextStars(star);
                            input.value = star.dataset.value;
                        });
                    }
                }
            } else {
                console.error("stars not found");
            }
        } else {
            console.error("input not found");
        }
    }
}

export function initShowMoreRatings(query = document.querySelectorAll('.js-showMoreRatings')){
    for(let btn of query){
        let list = btn.closest('.ratingList');
        if(list){
            let items = list.querySelectorAll('.ratingListItem');
            if(items){
                btn.addEventListener("click",function (){
                    let itemList = list.querySelectorAll('.ratingListItem');
                    let count = 0;
                    for(let item of itemList){
                        if(count >= 5){
                            break;
                        }
                        if(item.classList.contains("hidden") && count < 5){
                            item.classList.remove("hidden");
                            count++;
                        }
                    }
                    if(list.querySelectorAll('.ratingListItem.hidden').length === 0){
                        btn.classList.add("hidden");
                    }
                });
            }
        }
    }
}