import {isVisible} from "./utilities/visible";
import {checkFilledInput} from "./checkInputGroups";

function showOrderFields(fields) {
    for (let field of fields) {
        field.style.display = "block";
        for (let input of field.querySelectorAll('.isRequiredVisible')) {
            input.setAttribute("required", "");
            input.removeAttribute("readonly");
        }
    }
}

function hideOrderFields(fields) {
    for (let field of fields) {
        field.style.display = "none";
        for (let input of field.querySelectorAll('input')) {
            if (input.classList.contains("isRequiredVisible")) {
                input.removeAttribute("required");
                input.setAttribute("readonly", "");
            }
            input.setCustomValidity('');
            input.classList.remove('error');
            if (input.type === "checkbox") {
                input.checked = false;
            } else {
                input.value = "";
            }
        }
    }
}

function changeCountryFieldIfPackstationIsSelected() {
    let field = document.getElementById("input_shop_country_shipping");
    if (field) {
        field.value = "DE";
    }
}

function copyFieldsValues(toCopyFields, fields) {
    let count = 0;
    for (let id of toCopyFields) {
        let field = document.getElementById(id);
        let newField = document.getElementById(fields[count]);
        if (field && newField) {
            if (newField.value === "") {
                newField.value = field.value;
                checkFilledInput(newField);
            }
        }
        count++;
    }
}


function showPackstationFields() {
    showOrderFields(document.querySelectorAll('.js-orderPackstationFields'));
    changeCountryFieldIfPackstationIsSelected();
    copyFieldsValues([
        "input_surname",
        "input_lastname",
        "input_post_code",
        "input_city",
    ], [
        "input_surname_shipping_packstation",
        "input_lastname_shipping_packstation",
        "input_post_code_shipping_packstation",
        "input_city_shipping_packstation",
    ]);
}

function hidePackstationFields() {
    hideOrderFields(document.querySelectorAll('.js-orderPackstationFields'));
}

function showAlternativeShippingFields() {
    showOrderFields(document.querySelectorAll('.js-orderAlternativeShippingAddress'));
    copyFieldsValues([
        "input_surname",
        "input_lastname",
        "input_user_street",
        "input_user_street_no",
        "input_post_code",
        "input_city",
        "input_country",
    ], [
        "input_surname_shipping",
        "input_lastname_shipping",
        "input_user_street_shipping",
        "input_user_street_no_shipping",
        "input_post_code_shipping",
        "input_city_shipping",
        "input_shop_country_shipping",
    ]);
}

function hideAlternativeShippingFields() {
    hideOrderFields(document.querySelectorAll('.js-orderAlternativeShippingAddress'));
}

export function toggleOrderCreateUserFields() {
    let fields = document.querySelectorAll('.js-orderPasswordFields');
    let hiddenInputRegister = document.getElementById('hidden_register');
    if (fields && fields.length > 0) {
        let createAccountCheckbox = document.getElementById('input_createUserAccount');
        if (createAccountCheckbox && createAccountCheckbox.value === 'on') {
            let checkbox = document.getElementById('input_checked_new_account')
            checkbox.setAttribute("required", "");
            checkbox.removeAttribute("readonly");
        }

        for (let trigger of document.querySelectorAll('.js-orderShowCreateUserFields')) {
            trigger.addEventListener("change", function () {
                if (trigger.classList.contains("is-active")) {
                    trigger.classList.remove("is-active");
                    hideOrderFields(fields);
                    if (hiddenInputRegister) {
                        hiddenInputRegister.value = 0;
                    }
                } else {
                    trigger.classList.add("is-active");
                    showOrderFields(fields);
                    if (hiddenInputRegister) {
                        hiddenInputRegister.value = 1;
                    }
                }
            });
        }
    }
}

export function toggleOrderCompanyFields() {
    let fields = document.querySelectorAll('.js-orderCompanyFields');
    if (fields) {
        for (let trigger of document.querySelectorAll('.js-orderShowCompanyFields')) {
            trigger.addEventListener("change", function () {
                if (trigger.classList.contains("is-active")) {
                    trigger.classList.remove("is-active");
                    hideOrderFields(fields);
                } else {
                    trigger.classList.add("is-active");
                    showOrderFields(fields);
                }
            });
        }
    }
}

export function toggleOrderShippingCheckboxes() {
    let checkboxAlternativShipping = document.querySelector(".js-orderCheckAlternativeShipping");
    let checkboxPackstation = document.querySelector(".js-orderCheckPackstation");

    if (checkboxAlternativShipping) {
        checkboxAlternativShipping.addEventListener("change", function () {
            if (checkboxAlternativShipping.classList.contains("is-active")) {
                checkboxAlternativShipping.classList.remove("is-active");
                hideAlternativeShippingFields();
            } else {
                checkboxAlternativShipping.classList.add("is-active");
                showAlternativeShippingFields();
                changeRequiredAttributes();
                if (checkboxPackstation) {
                    hidePackstationFields();
                    checkboxPackstation.classList.remove("is-active");
                    checkboxPackstation.checked = false;
                }
            }
        });
    }

    if (checkboxPackstation) {
        checkboxPackstation.addEventListener("change", function () {
            if (checkboxPackstation.classList.contains("is-active")) {
                checkboxPackstation.classList.remove("is-active");
                hidePackstationFields();
            } else {
                checkboxPackstation.classList.add("is-active");
                showPackstationFields();
                if (checkboxAlternativShipping) {
                    checkboxAlternativShipping.classList.remove("is-active");
                    checkboxAlternativShipping.checked = false;
                    hideAlternativeShippingFields();
                }
            }
        });
    }
}

export function changeRequiredAttributes(query = document.querySelectorAll('.isRequiredVisible'), show = true) {
    for (let input of query) {
        if (isVisible(input) && show) {
            input.setAttribute("required", "");
            input.removeAttribute("readonly");
        } else {
            input.removeAttribute("required");
            input.setAttribute("readonly", "");
        }
    }
}


export function observeChanges() {
    const observer = new MutationObserver(() => {
        changeRequiredAttributes();
    });

    // Start observing the document body for changes in the subtree and childList
    observer.observe(document.body, {
        childList: true,
        subtree: true
    });

    // Initial call to handle elements already in the DOM
    changeRequiredAttributes();
}

export function addEventListenerToPrescriptionProvidingSelector() {

    let selects = document.getElementsByClassName("js-prescriptionProvidingSelector");

    if (selects.length === 0) {
        return;
    }

    let select = selects[0];
    select.addEventListener('change', function (event) {
        let selected = event.target.value;
        toggleAdditionalPrescriptionFieldsAndChangeRequirmets(selected);
    });
}


export function toggleAdditionalPrescriptionFieldsAndChangeRequirmets(selected) {
    if (selected === null) {
        let selects = document.getElementsByClassName("js-prescriptionProvidingSelector");

        if (selects.length === 0) {
            return;
        }

        let select = selects[0];
        selected = select.value;
    }

    let orbisanaCanRequestPrescriptionCheckboxes =
        document.getElementsByClassName("js-orbisanaCanRequestPrescription");

    if (orbisanaCanRequestPrescriptionCheckboxes.length > 0) {
        orbisanaCanRequestPrescriptionCheckboxes[0].style.display = "none";
    }

    let uploadInput =
        document.getElementsByClassName("js-prescriptionUpload");

    if (uploadInput.length > 0) {
        uploadInput[0].style.display = "none";
        uploadInput[0].getElementsByTagName('input')[0].removeAttribute("required");
    }

    let doctorName = document.getElementById("input_doctor_contact_name");

    if (doctorName) {
        doctorName.removeAttribute("required");
    }

    let doctorPhone = document.querySelector('label[for="input_doctor_contact_phone_no"]')
        .nextElementSibling.querySelectorAll('input');

    doctorPhone.forEach(function (input) {
        input.removeAttribute("required");
    });

    let doctorContactDiv = document.getElementsByClassName('doctorContact');

    if (doctorContactDiv.length > 0) {
        doctorContactDiv[0].style.display = "none";
    }

    if (selected == 3) {
        let orbisanaCanRequestPrescriptionCheckboxes =
            document.getElementsByClassName("js-orbisanaCanRequestPrescription");

        if (orbisanaCanRequestPrescriptionCheckboxes.length > 0) {
            orbisanaCanRequestPrescriptionCheckboxes[0].style.display = "block";
            orbisanaCanRequestPrescriptionCheckboxes[0].getElementsByTagName('input')[0].setAttribute("checked", true);
        }

        let doctorName = document.getElementById("input_doctor_contact_name");

        if (doctorName) {
            doctorName.setAttribute("required", true);
        }

        let doctorPhone = document.querySelector('label[for="input_doctor_contact_phone_no"]')
            .nextElementSibling.querySelectorAll('input');

        doctorPhone.forEach(function (input) {
            input.setAttribute("required", true);
        });

        let doctorContactDiv = document.getElementsByClassName('doctorContact');

        if (doctorContactDiv.length > 0) {
            doctorContactDiv[0].style.display = "block";
        }
    } else if (selected == 4) {
        let uploadInput =
            document.getElementsByClassName("js-prescriptionUpload");

        if (uploadInput.length > 0) {
            uploadInput[0].style.display = "block";
            uploadInput[0].getElementsByTagName('input')[0].setAttribute("required", true);
        }
    }
}