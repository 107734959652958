export function setSrcVideos(query = document.querySelectorAll('video')) {
    const windowWidth = window.outerWidth;

    for (let video of query) {
        for (let videoSource of video.querySelectorAll('source')) {
            let src = false;
            let srcDesktop = videoSource.dataset.src;
            let srcTablet = videoSource.dataset.tabletSrc;
            let srcMobile = videoSource.dataset.mobileSrc;

            if (srcDesktop !== undefined) {
                src = srcDesktop;
            }
            if (windowWidth < 1255 && srcTablet !== undefined) {
                src = srcTablet;
            }
            if (windowWidth < 768 && srcMobile !== undefined) {
                src = srcMobile;
            }

            if (src !== false) {
                videoSource.setAttribute('src', src);
            }
        }
        video.load();
    }
}