export function initJumpmarks(offset = 0, query = document.querySelectorAll('a[href^="#"]')) {
    for (let jumpmark of query) {
        jumpmark.addEventListener("click", function (e) {
            e.preventDefault();
            if (jumpmark.closest(".modal")) {
                return;
            }
            let href = jumpmark.getAttribute("href");
            if (href) {
                if (href === "#") {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    let target = document.getElementById(href.replace("#", ""));
                    if (target) {
                        let top = window.scrollY + target.getBoundingClientRect().top;
                        window.scrollTo({
                            top: top - offset,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }
            }
        });
    }
}