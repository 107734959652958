export function generateFAQRichSnippet() {
    var faqs = document.querySelectorAll('.faq-content .slidecontentItem');
    var faqList = [];

    faqs.forEach(function(faq) {
        var question = faq.querySelector('.slidecontentItem__headline') ? faq.querySelector('.slidecontentItem__headline').innerText : '';
        var answer = faq.querySelector('.slidecontentItem__content') ? faq.querySelector('.slidecontentItem__content').innerText : '';

        if (question && answer) {
            faqList.push({
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": answer
                }
            });
        }
    });

    if (faqList.length > 0) {
        var jsonLd = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqList
        };

        var script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(jsonLd);
        document.head.appendChild(script);
    }
}