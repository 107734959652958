export function addLoadingClass(el) {
    if (el) {
        el.classList.add("is-loading");
        el.classList.remove("is-success");
        el.classList.remove("is-error");
        el.classList.remove("is-action");
    }
}

export function removeLoadingClass(el) {
    if (el) {
        el.classList.remove("is-loading");
    }
}

export function addSuccessClass(el, done = function () {
}) {
    if (el) {
        el.classList.remove("is-loading");
        el.classList.remove("is-error");
        el.classList.remove("is-action");
        el.classList.add("is-success");
        setTimeout(function () {
            el.classList.remove("is-success");
            done();
        }, 2000);
    }
}

export function addErrorClass(el, done = function () {
}) {
    if (el) {
        el.classList.remove("is-loading");
        el.classList.remove("is-success");
        el.classList.remove("is-action");
        el.classList.add("is-error");
        setTimeout(function () {
            el.classList.remove("is-error");
            done();
        }, 2000);
    }
}

export function addActionClass(el, done = function () {
}) {
    if (el) {
        el.classList.remove("is-loading");
        el.classList.remove("is-success");
        el.classList.remove("is-error");
        el.classList.add("is-action");
        setTimeout(function () {
            el.classList.remove("is-action");
            done();
        }, 2000);
    }
}

export function addLoadingClassAfterSubmit(query = document.querySelectorAll('.js-loadingAfterSubmit')) {
    for (let btn of query) {
        btn.addEventListener("click", function () {
            addLoadingClass(btn);
            if (btn.getAttribute("form")) {
                setTimeout(function () {
                    btn.classList.remove("is-loading");
                }, 2000);
            }
        });
    }
}