export function prevAll(element,classFilter = "") {
    let result = [];

    while (element = element.previousElementSibling) {
        if(classFilter !== ""){
            if(element.classList.contains(classFilter)){
                result.push(element);
            }
        }else{
            result.push(element);
        }
    }
    return result;
}

export function nextAll(element,classFilter = "") {
    let result = [];

    while (element = element.nextElementSibling) {
        if(classFilter !== ""){
            if(element.classList.contains(classFilter)){
                result.push(element);
            }
        }else{
            result.push(element);
        }
    }
    return result;
}

export function reverseArr(input) {
    let ret = new Array;
    for (let i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
}

export function isTouchDevice() {
    var el = document.createElement('div');
    el.setAttribute('ontouchstart', 'return;');
    if (typeof el.ontouchstart == "function") {
        return true;
    } else {
        return false
    }
}

export function appendScriptToDOM(scriptText, addToBody = false) {
    var script = document.createElement("script");
    script.innerHTML = scriptText;
    if (addToBody) {
        document.body.appendChild(script);
    } else {
        document.head.appendChild(script);
    }
}

export function getScrollbarWidth() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}