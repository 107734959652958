export function itemComparisonStickyHeadline(){
    var comparisonTable = document.querySelector('.item-comparison-table');
    if (!comparisonTable) return;

    var firstCols = comparisonTable.querySelectorAll('.row > div:first-child');

    comparisonTable.addEventListener('scroll', function() {
        var scrollLeft = comparisonTable.scrollLeft;
        firstCols.forEach(function(col) {
            col.style.transform = 'translateX(' + scrollLeft + 'px)';
        });
    });
}

