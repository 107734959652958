export function playVideo(videos) {
        videos.forEach(vid => {
            const pauseBtn = vid.parentElement.querySelector(".pauseButton");
            const playBtn = vid.parentElement.querySelector(".playButton");

            if (playBtn) {
                playBtn.style.opacity = '1';
                pauseBtn.addEventListener("click", () => pauseVid(vid, pauseBtn, playBtn));
                playBtn.addEventListener("click", () => playVid(vid, pauseBtn, playBtn));
            }
        });

    function playVid(vid, pauseBtn, playBtn) {
        playBtn.style.display = 'none';
        pauseBtn.style.display = 'flex';
        vid.play();
    }

    function pauseVid(vid, pauseBtn, playBtn) {
        pauseBtn.style.display = 'none';
        playBtn.style.display = 'flex';
        vid.pause();
    }

}
