
export function isTouchDevice() {
    let touch = false;

    if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        touch = true;
    }

    if ('mousemove' in window) {
        touch = false;
    }

    return touch;
}