export function tabsCmsContent(tabsCmsContent){
    if(tabsCmsContent){
        let tab = document.querySelectorAll(".tabsCmsContent .tab");
        let cmsContent = document.querySelectorAll(".cmsContent");
        if(tab){
            for(let tabs of tab){
                tabs.addEventListener("click", function () {
                    let thisID = this.dataset.id;
                    let thisCmsContent = document.querySelector(".cmsContent-"+thisID);
                    for(let tabs of tab){
                        tabs.classList.remove("active");
                    }
                    for(let cmsContents of cmsContent){
                        cmsContents.classList.remove("d-block");
                        cmsContents.classList.add("d-none");
                    }
                    this.classList.add("active");
                    thisCmsContent.classList.add("d-block");
                })
            }
        }
    }
}