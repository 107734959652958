export function getChildrenClassForNestedItems(parent, childrenTagName = "ul", childrenClass = "hasChildren") {
    let hasChildren = false;
    let childrenLink = false;
    for (let children of parent.children) {
        if (children.tagName === childrenTagName.toUpperCase()) {
            hasChildren = true;
        }
        if (children.tagName === 'A' || children.tagName === 'SPAN') {
            childrenLink = children;
        }
    }

    if (hasChildren) {
        let triggerOpener = document.createElement("span");
        triggerOpener.classList.add("navChildrenToggle");
        childrenLink.appendChild(triggerOpener);

        const toggleFunction = function (e) {
            let closestLi = triggerOpener.closest('li');
            if (closestLi.classList.contains("open")) {
                triggerOpener.closest('li').classList.remove("open");
                triggerOpener.classList.remove("is-active");
            } else {
                triggerOpener.closest('li').classList.add("open");
                triggerOpener.classList.add("is-active");
            }
            e.preventDefault();
        };
        triggerOpener.addEventListener("click", toggleFunction);
        triggerOpener.addEventListener("touchend", toggleFunction);

        parent.classList.add(childrenClass);
    }
}

export function toggleChildrenSideNav(){
    var subnavContainer = document.querySelector('.subnav');

    if (subnavContainer) {
        var menuItems = subnavContainer.querySelectorAll('li.hasChildren > a');

        menuItems.forEach(function(menuItem) {
            const toggleFunction = function(event) {
                event.preventDefault();

                var parentLi = menuItem.parentNode;
                parentLi.classList.toggle('is-open');
                parentLi.classList.toggle('is-active');
                parentLi.classList.toggle('active');

                menuItem.classList.toggle('is-active');
                menuItem.classList.toggle('active');
            };
            menuItem.addEventListener('click', toggleFunction);
            menuItem.addEventListener('touchend', toggleFunction);
        });
    }
}

export function toggleLevel3Nav() {
    const level1Elements = document.querySelectorAll('.js-mainNavigation > ul > li.level_1');

    function initializeActiveState() {
        level1Elements.forEach(level1 => {
            const level2Elements = level1.querySelectorAll('li.level_2');
            const level3Elements = level1.querySelectorAll('li.level_3');
            let activeElementFound = false;

            [...level2Elements, ...level3Elements].forEach(levelItem => {
                if (levelItem.classList.contains('is-active') || levelItem.classList.contains('active_tree')) {
                    levelItem.classList.add('active_tree');
                    const anchor = levelItem.querySelector('a');
                    if (anchor) {
                        anchor.classList.add('active_tree');
                    }
                    activeElementFound = true;
                }
            });

            if (!activeElementFound && level2Elements.length > 0) {
                level2Elements[0].classList.add('active_tree');
                const anchor = level2Elements[0].querySelector('a');
                if (anchor) {
                    anchor.classList.add('active_tree');
                }
            }

            if (activeElementFound) {
                level1.classList.add('is-active');
                const level1Anchor = level1.querySelector(':scope > a');
                if (level1Anchor) {
                    level1Anchor.classList.add('is-active');
                }
            }
        });
    }

    function setupMouseEnterEvents() {
        const level1Elements = document.querySelectorAll('.js-mainNavigation > ul > li.level_1');

        level1Elements.forEach((level1) => {
            const level2Elements = level1.querySelectorAll('li.level_2');
            level2Elements.forEach((level2) => {
                let hoverTimeout;

                level2.addEventListener('mouseenter', function () {
                    hoverTimeout = setTimeout(() => {
                        deactivateOtherElements(level1, this);
                        this.classList.add('active_tree');
                        const anchor = this.querySelector('a');
                        if (anchor) {
                            anchor.classList.add('active_tree');
                        }
                    }, 200);
                });

                level2.addEventListener('mouseleave', function () {
                    clearTimeout(hoverTimeout);
                });

                level2.addEventListener('touchstart', function (event) {
                    event.stopPropagation();
                    const hasLevel3 = level2.querySelector('ul li.level_3') !== null;

                    if (hasLevel3) {
                        event.preventDefault();
                        deactivateOtherElements(level1, this);
                        this.classList.toggle('active_tree');
                        const level3Items = this.querySelectorAll('ul li.level_3');
                        level3Items.forEach(level3 => {
                            level3.classList.toggle('active_tree');
                        });
                    }
                });

                const level3Elements = level2.querySelectorAll('li.level_3');
                level3Elements.forEach((level3) => {
                    level3.addEventListener('touchstart', function (event) {
                        event.stopPropagation();
                        deactivateOtherElements(level1, this);
                        this.classList.add('active_tree');
                    });
                });
            });
        });
    }

    function deactivateOtherElements(level1, currentElement) {
        const allElements = level1.querySelectorAll('li.level_2, li.level_3');

        allElements.forEach(el => {
            el.classList.remove('active_tree', 'is-active-tree', 'is-active');
            const anchor = el.querySelector('a');
            if (anchor) {
                anchor.classList.remove('is-active-tree', 'active_tree', 'is-active');
            }
        });

        currentElement.classList.add('active_tree', 'is-active-tree');
        const currentAnchor = currentElement.querySelector('a');
        if (currentAnchor) {
            currentAnchor.classList.add('is-active-tree', 'active_tree');
        }

        if (currentElement.classList.contains('level_3')) {
            currentElement.classList.add('is-active');
            currentAnchor.classList.add('is-active');
        }
    }



    initializeActiveState();
    setupMouseEnterEvents();
}
