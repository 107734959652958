import {initTrustbars} from "../../../default/global/js/trustbar_orbisana";
import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {closeFilterBox, initFilter} from "../../../default/global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import {triggerFavoritesBtns} from "../../../default/global/js/favorites";
import {triggerComparisonBtns} from "../../../default/global/js/compare";
import {initQuantitySpinners} from "../../../default/global/js/quantitySpinner";
import hoverintent, {removeOtherHoverintents} from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {initRatingStars} from "../../../default/global/js/rating";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {openFullscreenGalleryType2} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal, triggerOpenLinkInModal, triggerOpenTextInModal} from "../../../default/global/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../default/global/js/basket";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "../../../default/global/js/itemListSlider";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {addLoadingClassAfterSubmit} from "../../../default/global/js/utilities/updateClasses";
import {initPasswordFields} from "../../../default/global/js/password";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initFlashmessages} from "../../../default/global/js/flashmessages";
import {addTableWrapper} from "../../../default/global/js/tableWrapper";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputBody} from "../../../default/global/js/inputfile";
import {initImageGallery} from "../../../default/global/js/imageGallery";
import {initImageGalleryType2} from "../../../default/global/js/imageGallery";
import initDcOrder from "../../../default/global/js/dcorder";
import {itemComparisonStickyHeadline} from "./custom/itemComparison";
/* New Modules */
import {playVideo} from "../../../default/global/js/videoButton";
import {tabsCmsContent} from "../../../default/global/js/tabsCmsContent";
import {checkScrollNav} from "../../../default/global/js/subnaviScroll";
import {scrollNav} from "../../../default/global/js/subnaviScroll";
import {subnaviScroll} from "../../../default/global/js/subnaviScroll";
import {initSendCollectionEntryMark} from "./custom/orbisanaSendMark";
import {togglePasswordInput} from "../../../default/global/js/togglePassword";
import {initScrolltopButton} from "../../../default/global/js/scrollTopButton";
import {shareItemcardModal} from "../../../default/global/js/shareItemcard";
import {triggerCopyLink} from "../../../default/global/js/copyToClipboard";
import {Tooltip} from "../../../default/global/js/tooltip";
import {toggleChildrenSideNav} from "../../../default/global/js/nav";
import {toggleLevel3Nav} from "../../../default/global/js/nav";
import {initCollectionFilter} from "./custom/collectionFilter";
import {generateFAQRichSnippet} from "./custom/faqRichSnippet";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {initDatepicker} from "../../../default/global/js/datepicker";

import {algoliaSearch, setupAlgoliaFilterWrappers, toggleAlgoliaSearch, updateSelectedFilters} from "./algolia/algolia";
import {algoliaDoAutoComplete} from "./algolia/algoliaAutoComplete";
import {reloadOnCookieClick} from "../../../default/global/js/utilities/cookie";
import {moveElementsToSpecialNavigation, limitSubMenuItems} from '../../../default/global/js/hoverMenu.js';


window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');
    const hoverMenu = document.querySelectorAll(".hoverMenu");

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendComparisonByJS = true;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }


    algoliaSearch();

    algoliaDoAutoComplete();
    toggleAlgoliaSearch();
    setupAlgoliaFilterWrappers();
    updateSelectedFilters();

    moveElementsToSpecialNavigation();
    limitSubMenuItems();

    toggleChildrenSideNav();

    initTrustbars();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    trimInputValues();

    focusInputGroups();

    initInputBody();

    initPhoneCountryFields();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    if (sendFavoriteByJS) {
        triggerFavoritesBtns();
    }

    if (sendComparisonByJS) {
        triggerComparisonBtns();
    }

    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerOpenLinkInModal();
    triggerConfirmModalLink();
    triggerOpenTextInModal();

    initQuantitySpinners();

    initToggleSlide();
    initRatingStars();

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.ais-Hits-item')) {
            hoverintent(el);
        }
    }

    if (IsTouchDevice) {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li > a')) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");
                if (!li.classList.contains("hoverintent")) {
                    removeOtherHoverintents();
                    li.classList.add("hoverintent");
                    e.preventDefault();
                } else {
                    li.classList.remove("hoverintent");
                }
            })
        }
    } else {
        for (let el of document.querySelectorAll('.js-mainNavigation ul li')) {
            hoverintent(el);
        }
    }

    if (IsTouchDevice) {
        for (let el of document.querySelectorAll('.js-secondNavigation > ul > li > a')) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");
                if (!li.classList.contains("hoverintent")) {
                    removeOtherHoverintents();
                    li.classList.add("hoverintent");
                    e.preventDefault();
                } else {
                    li.classList.remove("hoverintent");
                }
            })
        }
    } else {
        for (let el of document.querySelectorAll('.js-secondNavigation ul li')) {
            hoverintent(el);
        }
    }

    setSrcVideos();

    const videoElements = document.querySelectorAll('video');
    playVideo(videoElements);

    tabsCmsContent(document.querySelectorAll('.tabsCmsContent'));

    initSlideshows();

    initImageGallery();

    initImageGalleryType2();

    initScrollbars();

    initSlidecontents();

    initSendCollectionEntryMark();

    const checkBodyClass = () => {
            const body = document.querySelector('body');

            if (!body.classList.contains('isFullView')) {
                // Die Klasse "isFullview" ist nicht vorhanden, initItemlistSlider wird ausgeführt
                initItemlistSlider();
            } else {
                // Die Klasse "isFullview" ist vorhanden
                console.log('Die Klasse "isFullView" ist vorhanden.');
               }
    };


    checkBodyClass();


    initCustomSelects();

   // Tooltip, when necessary


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
       return new Tooltip(tooltipTriggerEl);
    });

    var submitButton = document.querySelector('.returnItemTooltip #submitButton');
    var tooltipWrapper = document.querySelector('.returnItemTooltip');
    var tooltipInstance = null;

    function initializeTooltips() {
        if (tooltipInstance) {
            tooltipInstance.dispose();
        }

        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip-hidden"]'));
        tooltipInstance = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }

    if (submitButton) {
        function toggleTooltip() {
            if (submitButton.disabled) {
                tooltipWrapper.setAttribute('data-toggle', 'tooltip-hidden');
                initializeTooltips();
            } else {
                tooltipWrapper.removeAttribute('data-toggle');
                if (tooltipInstance) {
                    tooltipInstance.forEach(function (instance) {
                        instance.dispose();
                    });
                    tooltipInstance = null;
                }
            }

        }

        toggleTooltip();

        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.target === submitButton && mutation.attributeName === 'disabled') {
                    toggleTooltip();
                }
            });
        });

        observer.observe(submitButton, {attributes: true});
    }

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    initFlashmessages();

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    for (let item of document.querySelectorAll(".js-openGalleryType2")) {
        item.addEventListener('click', function (event) {
            openFullscreenGalleryType2(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }

    lottieLoader();

    addLoadingClassAfterSubmit();
    initPasswordFields();

    addTableWrapper();

    initDcOrder();

    if(hoverMenu){
        for (let hoverMenus of hoverMenu) {
            hoverintent(hoverMenus);
        }
    }

    togglePasswordInput();

    initScrolltopButton(document.getElementById('scrollTopButton'),800);

    shareItemcardModal();
    triggerCopyLink();

    toggleLevel3Nav();

    initCollectionFilter();

    generateFAQRichSnippet();

    initDatepicker();


    const breadcrumbContainers = document.querySelectorAll('.breadcrumbWrapper');

        breadcrumbContainers.forEach((container) => {
            const breadcrumb = container.querySelector('.breadcrumb');
            if (breadcrumb.scrollWidth > breadcrumb.clientWidth) {
                breadcrumb.scrollLeft = breadcrumb.scrollWidth - breadcrumb.clientWidth;
            }
        });

    itemComparisonStickyHeadline();

    reloadOnCookieClick();
});

window.addEventListener('resize', () => {
    limitSubMenuItems();
});

// Funktionen beim Laden der Seite ausführen
document.addEventListener('DOMContentLoaded', () => {
    subnaviScroll('.secondNav .level_2', '.is-active'); // Zum aktiven Punkt scrollen
    checkScrollNav(); // Überprüfen und Ausblenden der Pfeile
});

// Eventlistener für die Pfeile hinzufügen
document.querySelector('.js-scrollNav-start').addEventListener('click', () => {
    scrollNav('left');
});

document.querySelector('.js-scrollNav-end').addEventListener('click', () => {
    scrollNav('right');
});

// Eventlistener für das Scrollen der Navigation hinzufügen
document.getElementById('navList').addEventListener('scroll', () => {
    checkScrollNav();
});
