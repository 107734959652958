import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {configure, hierarchicalMenu, hits, pagination} from 'instantsearch.js/es/widgets';

import '@algolia/autocomplete-theme-classic';

export function algoliaDoAutoComplete() {

    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
    let autoComplete = document.querySelector('.algoliaSearchWrapper');
    if(algolia_shop_filter){
        if(autoComplete){
            autocomplete({
                container: '.headerMain .autocomplete__search',
                panelContainer: '.headerMain .autocomplete__results',
                placeholder: searchPlaceHolder,
                detachedMediaQuery: 'none',
                onSubmit(params) {
                    if(params.state.query){
                        location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                    }
                },
                getSources({query}) {

                    return [{
                        sourceId: 'items',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient, queries: [{
                                    indexName: algoliaIndexName, query, params: {
                                        hitsPerPage: 12,
                                        filters: algolia_shop_filter,
                                    },
                                },],
                            });
                        },
                        templates: {
                            item({item, components, html}) {
                                return html`
                            <div class="aa-ItemWrapper">
                                <div class="aa-ItemContent">                                    
                                    <div class="aa-ItemContentBody">
                                        <a href="${item.itemLink}">
                                            <div class="aa-ItemContentImage">
                                                <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                            </div> 
                                            <div class="aa-ItemContentDescription">
                                                <span class="aa-ItemContentTitle">${item.description}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>`;
                            },
                        }
                        // ...
                    },];
                },
            });
        }
    }else{
            if(autoComplete){
                autocomplete({
                    container: '.headerMain .autocomplete__search',
                    panelContainer: '.headerMain .autocomplete__results',
                    placeholder: searchPlaceHolder,
                    detachedMediaQuery: 'none',
                    onSubmit(params) {
                        if(params.state.query){
                            location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                        }
                    },
                    getSources({query}) {

                        return [{
                            sourceId: 'items',
                            getItems() {
                                return getAlgoliaResults({
                                    searchClient, queries: [{
                                        indexName: algoliaIndexName, query, params: {
                                            hitsPerPage: 12,
                                        },
                                    },],
                                });
                            },
                            templates: {
                                item({item, components, html}) {
                                    return html`
                            <div class="aa-ItemWrapper">
                                <div class="aa-ItemContent">                                    
                                    <div class="aa-ItemContentBody">
                                        <a href="${item.itemLink}">
                                            <div class="aa-ItemContentImage">
                                                <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                            </div> 
                                            <div class="aa-ItemContentDescription">
                                                <span class="aa-ItemContentTitle">${item.description}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>`;
                                },
                            }
                            // ...
                        },];
                    },
                });
            }

    }

}