export function initActionClicks() {
    for (let target of document.querySelectorAll(".js-actionClick")) {
        let action = target.dataset.action;
        if (action) {
            target.addEventListener("click", function () {
                window.location.href = action;
            });
        }
    }
}

export function initFormSubmitClicks() {
    for (let target of document.querySelectorAll(".js-formSubmitClick")) {
        target.addEventListener("click", function () {
            target.closest('form').submit();
        });
    }
}

export function initFormSubmitChanges() {
    for (let target of document.querySelectorAll(".js-formSubmitChange")) {
        target.addEventListener("change", function () {
            target.closest('form').submit();
        });
    }
}