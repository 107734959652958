function showAllSearchedItems(filterItems) {
    for (const filter of filterItems) {
        filter.style.display = "";
    }
}

export function initSearchItems(query) {
    for (let input of query) {
        let wrapperClass = input.dataset.wrapper;
        let itemClass = input.dataset.item;
        let wrapper = input.closest("." + wrapperClass);
        if (wrapper) {
            let filterItems = wrapper.querySelectorAll("." + itemClass);

            if (filterItems) {
                input.addEventListener('keyup', () => {
                    let searchedValue = input.value.toUpperCase();

                    if (searchedValue === "" || searchedValue === null || searchedValue === undefined) {
                        showAllSearchedItems(filterItems);
                        return false;
                    }
                    for (let item of filterItems) {
                        let filterValues = item.textContent || item.innerText;

                        if (filterValues.toUpperCase().indexOf(searchedValue) > -1) {
                            item.style.display = "";
                        } else {
                            item.style.display = "none";
                        }
                    }
                });
            }
        }
    }
}