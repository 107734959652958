export function showIconbarHover(hover) {
    if (hover) {
        hover.classList.add("showIconbarHover");
    }
}

export function hideIconbarHover(hover) {
    if (hover) {
        hover.classList.remove("showIconbarHover");
    }
}

export function initIconbarHoverClose(query = document.querySelectorAll('.iconbarHover__close')) {
    for (let iconbarHoverClose of query) {
        iconbarHoverClose.addEventListener("click", function () {
            hideIconbarHover(iconbarHoverClose.closest(".iconbarItem"));
        });
    }
}